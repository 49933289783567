<template>
  <div>
    <h3>Informe</h3>
    <!-- <div v-show="ignoredBuildingsFields.length !== 0">
      <p class="text-danger">
        Los siguientes edificios no tienen calificación energética y han sido
        retirados del informe
      </p>
      <ul class="text-danger">
        <li v-for="(item, key) in ignoredBuildingsFields" :key="key">
          {{ item.denominacion }}
        </li>
      </ul>
    </div> -->

    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="primary"
            >Edificios comparados</b-button
          >
        </b-card-header>
        <b-collapse
          visible
          id="accordion-1"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-table :fields="buildingsFields" :items="comparedBuildingsFields">
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <br />
    <small>
      Haga click en cada línea para poder visualizar la gráfica asociada.
    </small>

    <b-list-group>
      <b-list-group-item
        button
        @click="showStats(0)"
        :active="selectedRow === 0"
      >
        <div class="row">
          <div class="col-10">Demanda energética de calefacción</div>
          <div class="col-2 font-weight-bold">
            {{ calAverage }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        button
        @click="showStats(1)"
        :active="selectedRow === 1"
        ><div class="row">
          <div class="col-10">Demanda energética de refrigeración</div>
          <div class="col-2 font-weight-bold">
            {{ refAverage }}
          </div>
        </div></b-list-group-item
      >
      <b-list-group-item
        button
        @click="showStats(2)"
        :active="selectedRow === 2"
        ><div class="row">
          <div class="col-10">Indicador global de emisiones</div>
          <div class="col-2 font-weight-bold">
            {{ emiAverage }}
          </div>
        </div></b-list-group-item
      >
      <b-list-group-item
        button
        @click="showStats(3)"
        :active="selectedRow === 3"
        ><div class="row">
          <div class="col-10">
            Indicador global consumo de energía no renovable
          </div>
          <div class="col-2 font-weight-bold ">
            {{ conAverage }}
          </div>
        </div></b-list-group-item
      >
    </b-list-group>

    <BarChart
      v-if="this.selectedRow !== null"
      class="chart"
      :data-set="chartData"
      :averageValue="averageValue"
      :marginLeft="100"
      :marginTop="100"
      :marginBottom="100"
      :tick-count="5"
      :bar-padding="0.5"
      :yLabel="yLabel"
    />

    <br />

    <br />

    <table v-show="selectedRow !== null" class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr>
          <th scope="col"></th>
          <th scope="col">Ahorro estimado</th>
          <th scope="col">Costes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(imp, index) in selectedArray" :key="index">
          <th scope="row">{{ imp.key }}</th>
          <td>{{ imp.data.ahorro }} %</td>
          <td>
            {{ Math.round(imp.data.coste.min / 100) * 100 }} -
            {{ Math.round(imp.data.coste.max / 100) * 100 }} €/m2
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="ignoredBuildingsFields.length !== 0"
      class="accordion"
      role="tablist"
    >
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="primary"
            >Edificios excluidos del informe</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
          <b-card-body>
            <b-table :fields="buildingsFields" :items="ignoredBuildingsFields">
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import BarChart from "@/components/BarChart.vue";
export default {
  name: "CompareBuildings",
  components: {
    BarChart,
  },

  data: () => ({
    buildingStats: [],
    calImprovementsArray: [],
    refImprovementsArray: [],
    emiImprovementsArray: [],
    conImprovementsArray: [],
    calAverage: null,
    refAverage: null,
    emiAverage: null,
    conAverage: null,
    ignoredBuildingsFields: [],
    comparedBuildings: [],
    comparedBuildingsFields: [],
    buildingsFields: [
      "id",
      { key: "denominacion", label: "Denominación" },
      { key: "construccion_year", label: "Año" },
    ],
    selectedRow: null,
  }),

  async created() {
    await this.compareBuildings();
  },

  computed: {
    chartData() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.graph_values;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.graph_values;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.graph_values;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.graph_values;
      } else return [];
    },
    yLabel() {
      if (this.selectedRow === 0) {
        return "kWh/m2año";
      } else if (this.selectedRow === 1) {
        return "kWh/m2año";
      } else if (this.selectedRow === 2) {
        return "kgCO2/m2año";
      } else if (this.selectedRow === 3) {
        return "kWh/m2año";
      } else return "";
    },
    averageValue() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.average;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.average;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.average;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.average;
      } else return [];
    },

    tableData() {
      if (this.selectedRow === 0) {
        return this.buildingStats.calefaccion.improvements;
      } else if (this.selectedRow === 1) {
        return this.buildingStats.refrigeracion.improvements;
      } else if (this.selectedRow === 2) {
        return this.buildingStats.emisiones.improvements;
      } else if (this.selectedRow === 3) {
        return this.buildingStats.consumo.improvements;
      } else return [];
    },

    selectedArray() {
      if (this.selectedRow === 0) {
        return this.calImprovementsArray;
      } else if (this.selectedRow === 1) {
        return this.refImprovementsArray;
      } else if (this.selectedRow === 2) {
        return this.emiImprovementsArray;
      } else if (this.selectedRow === 3) {
        return this.conImprovementsArray;
      } else return [];
    },
  },

  methods: {
    async compareBuildings() {
      const { data } = await api.post(`compare_buildings/`, {
        building_ids: this.$route.query.buildingIds,
      });

      this.buildingStats = data;
      this.comparedBuildings = JSON.parse(data.compared_buildings);
      this.comparedBuildingsFields = this.comparedBuildings.map((item) => {
        return { id: item.pk, ...item.fields };
      });

      const ignored = JSON.parse(this.buildingStats.rechazados);
      this.ignoredBuildingsFields = ignored.map((item) => {
        return { id: item.pk, ...item.fields };
      });

      this.parseImprovements(data);
      this.parseAverages();
    },

    parseImprovements(data) {
      Object.entries(data.calefaccion.improvements).forEach(([key, data]) => {
        this.calImprovementsArray.push({ key, data });
      });

      Object.entries(data.refrigeracion.improvements).forEach(([key, data]) => {
        this.refImprovementsArray.push({ key, data });
      });

      Object.entries(data.emisiones.improvements).forEach(([key, data]) => {
        this.emiImprovementsArray.push({ key, data });
      });

      Object.entries(data.consumo.improvements).forEach(([key, data]) => {
        this.conImprovementsArray.push({ key, data });
      });
    },

    parseAverages() {
      this.calAverage = Math.round(this.buildingStats.calefaccion.average);
      this.refAverage = Math.round(this.buildingStats.refrigeracion.average);
      this.emiAverage = Math.round(this.buildingStats.emisiones.average);
      this.conAverage = Math.round(this.buildingStats.consumo.average);
    },

    showStats(index) {
      this.selectedRow = index;
    },
  },
};
</script>

<style>
.chart {
  margin: 40px auto 0;
  display: block;
}
</style>
